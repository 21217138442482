import React from "react";
import { IconProps } from "../icon";

export const CloudSnowing_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 15.242a4.5 4.5 0 00-2.08-8.223 6.002 6.002 0 00-11.84 0A4.5 4.5 0 004 15.242M8 15h.01M8 19h.01M12 17h.01M12 21h.01M16 15h.01M16 19h.01"
    />
  </svg>
);
