import React from "react";
import { IconProps } from "../icon";

export const CloudSun_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10.5 1.5v1.6M3.6 10H2m3.451-5.049L4.32 3.82m11.23 1.131L16.68 3.82M19 10h-1.6M6.5 10A4 4 0 0114 8.062M6 22a4 4 0 111.324-7.775 5.002 5.002 0 019.352 0A4 4 0 1118 22H6z"
    />
  </svg>
);
