import React from "react";
import { IconProps } from "../icon";

export const ThermometerColdIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 12h10M9 4v16M3 9l3 3-3 3m9-9L9 9 6 6m0 12l3-3 1.5 1.5m9.5-1.965V4a2 2 0 10-4 0v10.535a4 4 0 104 0z"
    />
  </svg>
);
