import React from "react";
import { IconProps } from "../icon";

export const CloudLightningIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 15.744a4.502 4.502 0 00-1.08-8.725 6.002 6.002 0 00-11.84 0A4.5 4.5 0 005 15.744M13 10l-4 6h6l-4 6"
    />
  </svg>
);
