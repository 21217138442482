import React from "react";
import { IconProps } from "../icon";

export const CloudSun_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3.15 11a7.5 7.5 0 1114.784-2.5M6 22a4 4 0 11.337-7.986 6.003 6.003 0 0110.866-1.004A4.5 4.5 0 1117.5 22H6z"
    />
  </svg>
);
