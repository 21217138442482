import React from "react";
import { IconProps } from "../icon";

export const SunSetting_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 3v2M5.314 7.314L3.9 5.9m14.786 1.414L20.1 5.9M6 15a6 6 0 0112 0m4 0H2m17 4H5"
    />
  </svg>
);
