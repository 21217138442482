import * as CustomIcons from "./Custom";
import * as GeneralIcons from "./General";
import * as ArrowsIcons from "./Arrows";
import * as ChartsIcons from "./Charts";
import * as UsersIcons from "./Users";
import * as CommunicationIcons from "./Communication";
import * as FeedbackIcons from "./Feedback";
import * as MediaIcons from "./Media";
import * as LayoutIcons from "./Layout";
import * as ShapesIcons from "./Shapes";
import * as SecurityIcons from "./Security";
import * as FilesIcons from "./Files";
import * as DevelopmentIcons from "./Development";
import * as FinanceIcons from "./Finance";
import * as ImagesIcons from "./Images";
import * as EditorIcons from "./Editor";
import * as TimeIcons from "./Time";
import * as MapsIcons from "./Maps";
import * as WeatherIcons from "./Weather";
import * as EducationIcons from "./Education";
import * as OtherIcons from "./Other";
export default {
  ...CustomIcons,
  ...GeneralIcons,
  ...ArrowsIcons,
  ...ChartsIcons,
  ...UsersIcons,
  ...CommunicationIcons,
  ...FeedbackIcons,
  ...MediaIcons,
  ...LayoutIcons,
  ...ShapesIcons,
  ...SecurityIcons,
  ...FilesIcons,
  ...DevelopmentIcons,
  ...FinanceIcons,
  ...ImagesIcons,
  ...EditorIcons,
  ...TimeIcons,
  ...MapsIcons,
  ...WeatherIcons,
  ...EducationIcons,
  ...OtherIcons,
};
