import React from "react";
import { IconProps } from "../icon";

export const CloudMoonIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16.5 13a5.502 5.502 0 005.337-4.164 5.5 5.5 0 01-6.673-6.672 5.502 5.502 0 00-3.548 7.867M5 7V3M3 5h4M6 22a4 4 0 01-.679-7.943 6.003 6.003 0 0110.968-.892A4.5 4.5 0 1117.5 22H6.001z"
    />
  </svg>
);
