import React from "react";
import { IconProps } from "../icon";

export const Droplets_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 16a6 6 0 01-12 0c0-4.314 6-14 6-14s6 9.686 6 14zM8 9a3 3 0 11-6 0c0-2.157 3-7 3-7s3 4.843 3 7z"
    />
  </svg>
);
