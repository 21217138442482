import React from "react";
import { IconProps } from "../icon";

export const Snowflake_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 8v8m0-8V2m0 6L7 3m5 5l5-5m-5 13v6m0-6l-5 5m5-5l5 5m-1-9H8m8 0h6m-6 0l5-5m-5 5l5 5M8 12H2m6 0L3 7m5 5l-5 5"
    />
  </svg>
);
