import React from "react";
import { IconProps } from "../icon";

export const Wind_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.51 4.667A2 2 0 1111 8H2m10.51 11.333A2 2 0 1014 16H2m14.764-9A3 3 0 1119 12H2"
    />
  </svg>
);
