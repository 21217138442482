import React from "react";
import { IconProps } from "../icon";

export const Hurricane_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18 12a6 6 0 01-12 0m12 0a6 6 0 00-12 0m12 0a8 8 0 11-16 0m4 0a8 8 0 1116 0m-9 0a1 1 0 11-2 0 1 1 0 012 0z"
    />
  </svg>
);
