import React from "react";
import { IconProps } from "../icon";

export const Snowflake_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18.062 8.5l-12.124 7m12.124-7l1.098-4.098M18.062 8.5l4.098 1.098M5.938 15.5L1.84 14.402M5.938 15.5L4.84 19.598M18.062 15.5l-12.124-7m12.124 7l4.098-1.098M18.062 15.5l1.098 4.098M5.938 8.5L4.84 4.402M5.938 8.5L1.84 9.598M12 5v14m0-14L9 2m3 3l3-3m-3 17l-3 3m3-3l3 3"
    />
  </svg>
);
