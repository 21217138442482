import React from "react";
import { IconProps } from "../icon";

export const CloudSun_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19.368 12.405A5 5 0 1012 8m0 0a5.5 5.5 0 00-4.9 3.001L7 11a5 5 0 000 10h10.5a4.5 4.5 0 10-.206-8.995A5.502 5.502 0 0012 8z"
    />
  </svg>
);
