import React from "react";
import { IconProps } from "../icon";

export const CloudOffIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21.7 16.118a4.5 4.5 0 00-3.78-6.099 6.002 6.002 0 00-7.22-4.878M7.287 7.286a5.973 5.973 0 00-1.207 2.733A4.5 4.5 0 006.5 19h11c.456 0 .896-.068 1.311-.194M3 3l18 18"
    />
  </svg>
);
