import React from "react";
import { IconProps } from "../icon";

export const CloudRaining_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 18.5V21m-8-2.5V21m4-1.5V22m-5-7a5 5 0 11.1-9.999 5.502 5.502 0 0110.195 1.004A4.5 4.5 0 1117.5 15H6.999z"
    />
  </svg>
);
