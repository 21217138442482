import React from "react";
import { IconProps } from "../icon";

export const Cloud_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 19a4 4 0 01-.999-7.874L5 11a7 7 0 0113.96-.758A4.502 4.502 0 0117.5 19H6z"
    />
  </svg>
);
