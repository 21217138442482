import React from "react";
import { IconProps } from "../icon";

export const Moon_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 15.844a10.424 10.424 0 01-4.306.925c-5.779 0-10.463-4.684-10.463-10.462 0-1.536.33-2.994.925-4.307A10.464 10.464 0 002 11.538C2 17.316 6.684 22 12.462 22c4.243 0 7.896-2.526 9.538-6.156z"
    />
  </svg>
);
