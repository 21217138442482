import React from "react";
import { IconProps } from "../icon";

export const CloudRaining_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 15.242a4.5 4.5 0 00-2.08-8.223 6.002 6.002 0 00-11.84 0A4.5 4.5 0 004 15.242M12.25 15l-2.8 7m7.6-9l-2.8 7m-5.2-7l-2.8 7"
    />
  </svg>
);
