import React from "react";
import { IconProps } from "../icon";

export const SunIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 2v2m0 16v2M4 12H2m4.314-5.686L4.9 4.9m12.786 1.414L19.1 4.9M6.314 17.69L4.9 19.104m12.786-1.414l1.414 1.414M22 12h-2m-3 0a5 5 0 11-10 0 5 5 0 0110 0z"
    />
  </svg>
);
